/* Estilos Globais */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Fonte moderna e legível */
  color: #333; /* Cor do texto padrão */
  background-image: url('./body.jpg');
  background-size: cover; /* Faz a imagem cobrir toda a área */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Evita que a imagem se repita */
}

/* Animação de gradiente de fundo */
 

/* Estilos para links */
a {
  text-decoration: none;
  color: #007bff; /* Azul vibrante para links */
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

a:hover {
  color: #0056b3; /* Azul escuro para hover */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Sombra suave para links */
}

/* Estilo para botões */
button {
  font-family: inherit;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #007bff; /* Azul vibrante para fundo do botão */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #0056b3; /* Azul escuro para hover */
  transform: scale(1.05); /* Efeito de aumento suave */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Sombra mais pronunciada */
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Contorno azul claro para foco */
}

/* Estilos para títulos */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif; /* Fonte elegante para títulos */
  margin: 0;
  padding: 0;
  color: #222;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 700;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: 600;
}

h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 500;
}

h4 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  font-weight: 400;
}

/* Estilos para parágrafos */
p {
  margin: 0 0 15px;
  line-height: 1.8;
  color: #555; /* Cor do texto mais suave */
}

/* Estilo para seções */
section {
  padding: 30px;
  background-color: #fff; /* Fundo branco para seções */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra mais forte */
  margin: 20px auto;
  max-width: 1200px;
}

/* Estilos para cartões de produtos */
.card {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card img:hover {
  transform: scale(1.1);
}

.card-content {
  padding: 20px;
  text-align: center;
}

.card-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #333;
}

.card-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
}
